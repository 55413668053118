import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tool-page" }
const _hoisted_2 = {
  class: "tool-box",
  "dark-class": "tool-box-dark"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "explain" }
const _hoisted_6 = {
  key: 0,
  class: "child-box"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "arrow-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!
  const _component_elem_prompt = _resolveComponent("elem-prompt")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tools, (item, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["item-box", { disable: !item.href }]),
          key: idx,
          onClick: ($event: any) => (_ctx.jump(item.href))
        }, [
          _createElementVNode("div", {
            class: "head-color",
            style: _normalizeStyle({ 'background-color': item.color })
          }, null, 4),
          _createElementVNode("div", {
            class: "icon-box",
            style: _normalizeStyle({ 'background-color': item.color })
          }, [
            _createVNode(_component_elem_icon, {
              src: "static/icon/tool/",
              name: item.icon
            }, null, 8, ["name"])
          ], 4),
          _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(item.explain), 1),
          (item.child)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.child, (c, c_idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "item-box",
                    key: c_idx,
                    onClick: _withModifiers(($event: any) => (_ctx.jump(c.href)), ["stop"])
                  }, [
                    _createVNode(_component_elem_icon, {
                      src: "static/icon/tool/",
                      name: c.icon
                    }, null, 8, ["name"]),
                    _createVNode(_component_elem_prompt, {
                      title: c.title
                    }, null, 8, ["title"])
                  ], 8, _hoisted_7))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_elem_icon, {
                  class: "icon",
                  name: "arrow_right"
                })
              ]))
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}