
import Component, { ComponentMethods } from "@/module/component/component"

import elemIcon from "@/components/elem-icon.vue"
import elemPrompt from "@/components/elem-prompt.vue"

class ToolView extends ComponentMethods implements ComponentEntity {
    public title: string = "工具箱"

    private tools = [
        {
            icon: "blocklist",
            href: "/table?name=AdminAccount",
            title: "管理员用户",
            explain: "可对管理员用户进行添加、管理的操作。",
            color: "#117bbd",
        },
        {
            icon: "blocklist",
            href: "/table?name=BlockList",
            title: "限制名单",
            explain: "通过限制 IP 进行限制访问，包括 API 请求 和 资源文件 的任何访问。",
            color: "#000",
        },
        {
            icon: "allowlist",
            href: "/table?name=Allowlist",
            title: "准许名单",
            explain: "通过添加准许 IP 进行无限制的访问，即使超过访问频率限制的次数也不会进行拦截。",
            color: "#87dbb1",
        },
        {
            icon: "exception",
            href: "/table?name=Exception",
            title: "程序异常",
            explain: "通过查阅程序异常信息，快速定位异常程序所在以及查看异常详情信息。",
            color: "#da5231",
        },
        {
            icon: "access",
            href: "/table?name=Access",
            title: "访问日志",
            explain: "访问日志包括详细的访问数据、请求参数、IP地址等。",
            color: "#2faaf7",
        },
        {
            icon: "order",
            href: "/table?name=OrdersException",
            title: "异常订单",
            explain: "可查阅所有关于订单的异常信息。",
            color: "#FF2C61",
        },
        {
            icon: "language",
            title: "语言包",
            explain: "管理各终端程序与管理端的语言包。",
            color: "#6451ff",
            child: [
                {
                    icon: "language_sign",
                    href: "/table?name=LanguageSign",
                    title: "语言标识符",
                },
                {
                    icon: "language_package",
                    href: "/table?name=Language",
                    title: "语言包",
                },
            ],
        },
        {
            icon: "collect",
            href: "/table?name=Collect",
            title: "数据收集",
            explain: "临时数据收集用于分析数据结构和异常信息。",
            color: "#4889A7",
        },
        {
            icon: "collect",
            href: "/table?name=Domain",
            title: "域名管理",
            explain: "用于配置域名使用权限。",
            color: "#4889A7",
        },
    ]

    components = {
        elemIcon,
        elemPrompt,
    }
}

export default Component.build(new ToolView())
